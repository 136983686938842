import './SocialLink.css';

import linkedinIcon from '../../assets/icons/linkedin-icon.png';
import facebookIcon from '../../assets/icons/facebook-icon.png';
import instaIcon from '../../assets/icons/insta-icon.png';
import whatsappIcon from '../../assets/icons/whatsapp-icon.png';

import React from 'react';

const SocialLink = ({ id, url, socialLinkType, icon }) => {
  function getImage(val) {
    var answer = {};
    switch (val) {
      case 'LINKEDIN':
        answer = linkedinIcon;
        break;
      case 'FACEBOOK':
        answer = facebookIcon;
        break;
      case 'INSTAGRAM':
        answer = instaIcon;
        break;
      case 'WHATSAPP':
        answer = whatsappIcon;
        break;
    }
    return answer;
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img
        src={getImage(socialLinkType)}
        alt={`${socialLinkType} Icon`}
        className="social-icon"
      />
    </a>
  );
};

export default SocialLink;
