import React, { useEffect, useState } from 'react'
import './ResearchTopicsList.css'
import { REST } from '../../config/AxiosConfig'
import ButtonArrow from '../Button/ButtonArrow'
import TitleDescriptionRow from '../TitleDescriptionRow/TitleDescriptionRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../Pagination/Pagination'
import { t } from 'i18next'

const ResearchTopicsList = () => {
  const [researchFields, setResearchFields] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [page, setPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [setselectedResearch, setSetselectedResearch] = useState(null)
  const pageSize = 5 // Number of items to show per page

  useEffect(() => {
    getResearchFields().then(() => {
    })
  }, [])

  const getResearchFields = async () => {
    await REST.get(`/organisation/research-field/~`)
      .then((response) => {
        setResearchFields(response.data)
      })
      .catch((error) => {
        // todo handle errors
      })
  }

  // Filter the researchFields array based on the search term
  const filteredResearchFields = researchFields.filter(({ title }) =>
    title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredResearchFields.length / pageSize)

  // Calculate the indexes of the items to show on the current page
  const startIndex = (currentPage - 1) * pageSize
  const endIndex = startIndex + pageSize

  // Slice the filteredResearchFields array based on the current page
  const paginatedResearchFields = filteredResearchFields.slice(
    startIndex,
    endIndex
  )

  const handleChangePage = (e) => {
    setCurrentPage(e.selected + 1)
  }

  return (
    <div className='bg-white p-4'>
      <div className='container'>
        <div className='position-relative mb-3'>
          <input
            className='w-100 p-2'
            style={{ border: '1px solid #707070' }}
            placeholder={t('searchTermPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{
              color: '#707070',
              position: 'absolute',
              right: '10px',
              top: '50%',
              fontSize: '1.5rem',
              transform: 'translateY(-50%)',
            }}
          />
        </div>
        {/* Horizontal line */}
        <div></div>

        {/* research rows */}
        {paginatedResearchFields.map(({ title }, index) => (
          <TitleDescriptionRow
            key={index}
            title={title}
            onClick={() => {
              console.log(title)
            }}
          />
        ))}

        {/* Pagination controls */}
        <Pagination
          handlePageChange={handleChangePage}
          totalPages={totalPages}
          currentPage={currentPage}
        />

        {/* Render selected research details */}
        {setselectedResearch && (
          <div>
            <h2>{setselectedResearch.title}</h2>
            {/* Render other details here */}
          </div>
        )}
      </div>
    </div>
  )
}

export default ResearchTopicsList
