import ButtonArrow from "../Button/ButtonArrow";
import "./CardPanelWhite.css";

function CardPanelWhite(props) {
  const {
    title,
    subtitle,
    text,
    width,
    image,
    id,
    height,
    reverseOrder,
    redirectPath,
    hasButton = true,
  } = props;

  const cardStyle = {
    width: width,
    height: height,
    backgroundImage:
      height === "450px" ? `url(data:image/jpeg;base64,${image})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    border: "none",

  };

  return (
    <div
      className={`col d-flex flex-column gap-2 flex-lg-row ${
        reverseOrder ? "flex-lg-row-reverse" : ""
      }`}
    >
      <div className="card col" style={cardStyle}>
        <div className="card-body d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-between ps-3 pt-3 w-100">
            <div className="overflow-hidden">
              <h5 className="card-title card-panel-title">{title}</h5>
              <span className="card-subtitle mb-2 card-panel-span">
                {subtitle}
              </span>
            </div>
            <div
              className="multiline-ellipsis"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {hasButton && ( 
              <div className="align-self-end">
                <ButtonArrow to={`/${redirectPath}/${id}`} />
              </div>
            )}
          </div>
        </div>
      </div>
      {height !== "450px" && image && (
        <img
          className="mx-auto align-self-center card-white-img "
          src={`data:image/jpeg;base64,${image}`}
          alt="poza"
        />
      )}
    </div>
  );
}

export default CardPanelWhite;
