

const language = localStorage.getItem("lang");

const LanguageHeader = language && language
    ? {
        "Accept-Language": language,
        "Content-Type": "application/json"
    }
    : {};

export default LanguageHeader;
