import './DocumentContact.css';
import googleImage from '../../assets/icons/google-icon.png';
import pdfImage from '../../assets/icons/pdf-icon.png';

function Document({ titleDoc, iconDoc, dateDoc, fileType }) {
  const formattedDate = new Date(dateDoc);

  function getDocumentImage(val) {
    var answer = {};
    // eslint-disable-next-line default-case
    switch (val) {
      case 'DOCX':
        answer = googleImage;
        break;
      case 'PDF':
        answer = pdfImage;
        break;
      default:
        answer = googleImage;
    }
    return answer;
  }

  return (
    <div className="container-doc d-flex">
      {iconDoc && (
        <div className="d-flex flex-row align-items-start">
          <img src={getDocumentImage(fileType)} alt="document icon" />
          {/* <img src={iconDoc} alt="document icon" /> */}
        </div>
      )}
      <div className="d-flex flex-column gap-0">
        <p className="title-doc">{titleDoc}</p>
        <p className="data-doc">
          Data upload: {formattedDate.toLocaleDateString()}
        </p>
      </div>
    </div>
  );
}

export default Document;
