import "./ButtonHero.css";
import arrowIcon from "../../assets/icons/ArrowWhite.png";
import { useTranslation } from "react-i18next";

function ButtonHero() {
  const { t } = useTranslation();

  return (
    <div
      onClick={() =>
        window.open(
          "https://www.research-hub-backend.tuiasi.ro/sign-in",
          "_blank"
        )
      }
      className="btn text-white"
      style={{ width: "240px" }}
      id="btn-custom"
    >
      <div style={{ display: "flex", justifyItems: "center", width: "170px" }}>
        <span style={{ fontStyle: "normal" }}>{t("signUpNow")}</span>
        <img src={arrowIcon} alt="arrow icon" className="ms-3" />
      </div>
    </div>
  );
}

export default ButtonHero;
