import React, { useEffect, useState } from "react";
import HeroImage from "../../assets/images/HeroDD.png";
import ArticlePanel from "../../components/ArticlePanel/ArticlePanel";
import CardPanelWhite from "../../components/CardPanelWhite/CardPanelWhite";
import HeroSection from "../../components/HeroSection/HeroSection";
import HrLine from "../../components/HrLine/HrLine";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import SearchBar from "../../components/SearchBar/SearchBar";
import SelectBar from "../../components/SelectBar/SelectBar";
import Title from "../../components/Typography/Title";
// import articles from './data';
import { REST } from "../../config/AxiosConfig";
import { useTranslation } from 'react-i18next';
import "./ProjectsList.css";

function ProjectsList() {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [title, setTitle] = useState("");
  const [researchFields, setResearchFields] = useState([]);

  const { t } = useTranslation();

  var searchBody = {
    keyword: title.length > 3 ? title : "",
    researchFields: researchFields.length > 0 ? researchFields.map((x) => x.id) : [],
    projectStatus: "APPROVED",
  };

  const getProjects = async () => {
    await REST.post(`/project/~?page=${currentPage}&size=4`, searchBody)
      .then((response) => {
        setProjects(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    getProjects();
  }, [currentPage, title, researchFields]);

    const handleChangePage = (e) => {
        setCurrentPage(e.selected);
    };

  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle={t("projectTitle")}
        showButton={false}
      />
      <section className="container-fluid d-flex  justify-content-center mt-5">
        <div className="d-flex flex-column flex-xl-row  gap-3 mx-auto">
          <CardPanelWhite
            title="Eveniment cu echipa"
            subtitle="12.01.2022 - 11:10 AM - Aula"
          />
          <CardPanelWhite
            title="Eveniment cu echipa"
            subtitle="12.01.2022 - 11:10 AM - Aula"
          />
          <CardPanelWhite
            title="Eveniment cu echipa"
            subtitle="12.01.2022 - 11:10 AM - Aula"
          />
        </div>
      </section>
      <HrLine />
      <section className="container mb-3">
        <Title title={t("projectTitle")} />
        {/* <SubTitle subtitle={center?.shortDescription} uppercase /> */}
      </section>

      <section className="articles-section container">
        <div className="container search-section d-flex flex-row ">
          <SearchBar
            handleInputChange={(event) => {
              setTitle(event.target.value);
            }}
            targetEvent={title}
          />
          <SelectBar handleFieldChange={(event) => {
              setResearchFields(event);
          }}/>
        </div>

        <ArticlePanel
          articles={projects.content}
          itemsPerPage={4}
          redirectUrl={"project"}
        />

        <Pagination
          totalPages={projects.totalPages}
          handlePageChange={handleChangePage}
          currentPage={currentPage}
        />
      </section>
    </Layout>
  );
}

export default ProjectsList;
