import React from 'react';
import './Numbers.css';

function Numbers(props) {
  return (
    <div className="container-number d-flex flex-column col-6 col-lg-3 justify-content-between align-items-center text-center">
      <p className="text-number">{props.text}</p>
      <div className="d-flex flex-row">
        <span className="span-plus">+</span>
        <p className="span-digit">{props.number}</p>
      </div>
    </div>
  );
}

export default Numbers;
