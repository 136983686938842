import './HrLine.css';

function HrLine() {
  return (
    <div className="hr-line mt-5 mb-5">
      <hr />
    </div>
  );
}

export default HrLine;
