import ButtonHero from "../ButtonHero/ButtonHero";
import "./Banner.css";

const Banner = ({ imageUrl, title, height, showButton }) => {
  const bannerStyle = {
    backgroundImage: `url(${imageUrl})`,
    height: `${height}px`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "flex-start", // Use 'flex-start' instead of 'start' for better compatibility
    justifyContent: "center",
    flexDirection: "column",
  };

  return (
    <div style={bannerStyle} className="text-white mt-5 ps-5">
      <h1 className="title-banner">{title}</h1>
      {showButton ? <ButtonHero /> : null}
    </div>
  );
};

export default Banner;
