import React from 'react'
import ReactPaginate from 'react-paginate'
import './Pagination.css'
function Pagination({ totalPages, handlePageChange, currentPage }) {
  return (
    totalPages > 1 && (
      <div className='flex justify-center'>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          pageClassName='page-item'
          activeClassName={'active'}
        />
      </div>
    )
  )
}

export default Pagination
