import React, { useEffect, useState } from 'react';
import HeroImage from '../../assets/images/HeroDD.png';
import ArticlePanel from '../../components/ArticlePanel/ArticlePanel';
import CardPanelWhite from '../../components/CardPanelWhite/CardPanelWhite';
import HeroSection from '../../components/HeroSection/HeroSection';
import HrLine from '../../components/HrLine/HrLine';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import SearchBar from '../../components/SearchBar/SearchBar';
import SelectBar from '../../components/SelectBar/SelectBar';
import Title from '../../components/Typography/Title';
// import articles from './data';
import { REST } from '../../config/AxiosConfig';
import './ArticlesList.css';

function ArticlesList() {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(0);
  const [title, setTitle] = useState("");

  var searchBody = {
    keyword: title.length > 3 ? title : '',
    researchFields: [],
    articleStatus: "APPROVED"
  };

  const getAllArticles = async () => {
    await REST.post(`/article/~?page=${page}&size=10`, searchBody)
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };



  useEffect(() => {
    getAllArticles();
    // eslint-disable-next-line
  }, [page, searchBody.keyword]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    console.log(newPage);
  };


  return (


    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        color="#FAFAFA"
        contentBottom="end"
        shortDescription={'Articolele TUIASI-HUB'}
      />
      <section className="container-fluid d-flex  justify-content-center mt-5">
        <div className="d-flex flex-column flex-lg-row gap-3 mx-auto">
          <CardPanelWhite
            title="Eveniment cu echipa"
            subtitle="12.01.2022 - 11:10 AM - Aula"
          />
          <CardPanelWhite
            title="Eveniment cu echipa"
            subtitle="12.01.2022 - 11:10 AM - Aula"
          />
          <CardPanelWhite
            title="Eveniment cu echipa"
            subtitle="12.01.2022 - 11:10 AM - Aula"
          />
        </div>
      </section>
      <HrLine />
      <section className="container mb-3">
        <Title title={'Articolele TUIASI-HUB'} />
        {/* <SubTitle subtitle={center?.shortDescription} uppercase /> */}
      </section>

      <section className="articles-section container">
        <div className="container search-section d-flex flex-row ">
          <SearchBar
            handleInputChange={(event) => {
              setTitle(event.target.value)
            }}
            targetEvent={title}
          />
          <SelectBar />
        </div>
        <HrLine />
        <ArticlePanel
          articles={articles?.content}
          itemsPerPage={4}
          redirectUrl={'article'}
        />
        <Pagination
          totalPages={articles.totalPages}
          handlePageChange={handleChangePage}
          currentPage={page + 1} />
      </section>
    </Layout>
  );
}

export default ArticlesList;
