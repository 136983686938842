import React from "react";
import "./HeroSection.css";
import ButtonHero from "../ButtonHero/ButtonHero";

function HeroSection({
  backgroundImage,
  shortDescription,
  color,
  subtitle,
  contentBottom,
  showButton,
}) {
  return (
    <div
      className="hero-container d-flex justify-content-start"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center 30%",
      }}
    >
      <div className="hero-content" style={{ "--alignSelf": contentBottom }}>
        <div>
          <h1 className="titleHero" style={{ color: `${color}` }}>
            {/* Let's have a moment <span>of science</span> */}
            {shortDescription}
          </h1>
          <p
            className="hero-text"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></p>
        </div>

        {showButton && <ButtonHero />}
      </div>
    </div>
  );
}

export default HeroSection;
