import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

function Layout(props) {
  return (
    <div>
      <Navbar />
      <div style={{ background: "#F6F8FA 0% 0% no-repeat padding-box" }}>
        {props.children}
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
