import ButtonArrow from "../Button/ButtonArrow";
import "./CardPanel.css";
import { useTranslation } from "react-i18next";

function CardPanel(props) {
  const { t } = useTranslation();
  return (
    <div className="card-panel">
      <h1 className="card-panel-title">{t("researchCenterTitle")}</h1>
      <span className="card-panel-span">
        {t("researchCenterDescriptionTitle")}
      </span>
      <p className="card-panel-p">{t("researchCenterDescription")}</p>
      <ButtonArrow to="/centers" buttonText={t("explore")} />
    </div>
  );
}

export default CardPanel;
