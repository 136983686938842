import "./SearchBarBig.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function SearchBarBig() {
  return (
    <div className="d-flex position-relative max-width-90 mx-auto">
      <div className="input-group mx-auto" style={{ width: "65rem" }}>
        <input
          type="text"
          className="form-control rounded-pill p-3 "
          id="input-search-bar-big"
          placeholder="Cautare dupa termeni cheie"
          style={{ borderRadius: "53px" }}
        />
        <div className=" position-absolute end-0 h-100">
          <button
            className="btn rounded-pill px-5 py-3 searchBar-btn h-100"
            style={{ borderRadius: "53px" }}
          >
            <span className="font-weight-normal font-style-normal d-none d-sm-inline">
              Cautare in HUB
            </span>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{ color: "#ffffff" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchBarBig;
