import axios from "axios";
import LanguageHeader from "../config/LanguageHeader";

// const lang = localStorage.getItem("lang");

export const baseUrl = "https://backend.research-hub.tuiasi.ro/api";
// const baseUrl = "http://localhost:8080/api"

// console.log(lang);
export const REST = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend", ...LanguageHeader },
});
