import { baseUrl } from "../../config/AxiosConfig";
import DocumentContact from "../DocumentContact/DocumentContact";
// import { useParams } from 'react-router-dom';
import SocialLink from "../SocialLink/SocialLink";
import SubTitle from "../Typography/SubTitle";
import Title from "../Typography/Title";
import "./ContactSection.css";
import profilePicture from "../../assets/images/profilePicture.jpg";

function ContactSection({ user, documents }) {
  return (
    <div
      className="container-contact-section container-fluid "
      style={{ paddingTop: "150px" }}
    >
      <div className="row">
        <div className=" col-lg-4 align-items-center ">
          {user.profilePicture !== null ? (
            <img
              className="img-contact"
              style={{ maxWidth: "500px" }}
              src={`data:image/jpeg;base64,${user?.profilePicture}`}
              alt="researcher"
            />
          ) : (
            <img
              className="img-contact"
              style={{ maxWidth: "500px" }}
              src={profilePicture}
              alt="researcher"
            />
          )}
        </div>
        <div className="col-lg-4 ">
          <h1 className="mb-3">
            {user?.firstName} {user?.lastName}
          </h1>
          <Title title="Dr. Ing, Prof. Univ." className="mb-0" />
          {/* <SubTitle subtitle="Hidrologie" color="#33418E" /> */}
          <p className="mt-4 " style={{ fontSize: "1.1rem" }}>
            {user?.description}
          </p>
        </div>
        <div className="col-lg-4">
          <h3 className="pt-3">Contact:</h3>
          <h4 className="fw-bold mb-0 mt-4">Email</h4>
          <p>{user?.email}</p>
          <br />
          <div className="border-left">
            <h3 className="mt-4 labels">Social Media</h3>
            <div className="icons-wrapper mb-5">
              {user?.socialLinks?.map((socialLink) => (
                <SocialLink
                  key={socialLink.id}
                  url={socialLink.url}
                  socialLinkType={socialLink.socialLinkType}
                />
              ))}
            </div>

            <h3 className="mb-3 labels">Documente Profesionale</h3>
            <div className=" labels">
              {documents?.map((doc, key) => (
                <a
                  style={{ textDecoration: "none" }}
                  href={`${baseUrl}/users/${doc.id}/download`}
                  target="_blank"
                >
                  <DocumentContact
                    titleDoc={doc.fileName}
                    iconDoc={doc.fileType}
                    dateDoc="2021-05-05T12:00:00Z"
                    fileType={doc?.fileType}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
