import React, { useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import Layout from "../../components/Layout/Layout";
import { REST } from "../../config/AxiosConfig";
import HeroImage from "../../assets/images/doctors.png";
import "./Contact.css";
import Pagination from "../../components/Pagination/Pagination";
import { useTranslation } from "react-i18next";

function Members() {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);

  const getUsers = async () => {
    await REST.get(`/users/~?page=${page}&size=8`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const handleChangePage = (event) => {
    setPage(event.selected);
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        color="#FAFAFA"
        contentBottom="end"
        shortDescription={"Echipa TUIASI-HUB"}
      />

      <section className="container section-people mt-5 mb-5">
        <h2 className="card-panel-title">{t("teamTitle")}</h2>
        <h2 className="mb-5 card-panel-title">
          {t("all")}
          <span className="card-panel-span"> A B C D E F G H I J K L M</span>
        </h2>
        <div className="container d-flex flex-row images-container ">
          {users?.content?.map(
            (user, key) =>
              user.id != 4 && (
                <ImageGallery
                  id={user?.id}
                  shortDescription={user?.shortDescription}
                  profilePicture={user?.profilePicture}
                  fullName={user?.firstName + " " + user?.lastName}
                  key={user?.id}
                />
              )
          )}
        </div>

        <h4 id="pagin" className="mt-5 ms-5">
          <Pagination
            totalPages={users.totalPages}
            handlePageChange={handleChangePage}
            currentPage={page}
          />
        </h4>
      </section>
    </Layout>
  );
}

export default Members;
