import React from "react";
import ButtonArrow from "../Button/ButtonArrow";
import "./TitleDescriptionRow.css";
import { useTranslation } from "react-i18next";

const TitleDescriptionRow = ({ title, onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-column flex-lg-row justify-content-between title-description-row p-4"
      onClick={onClick}
    >
      <div>
        <h2 style={{ color: "#33418E" }}>{title}</h2>
      </div>
      <ButtonArrow to="/projects" buttonText={t("more")} />
    </div>
  );
};

export default TitleDescriptionRow;
