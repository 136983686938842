import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticlePanel from "../../components/ArticlePanel/ArticlePanel";
import Banner from "../../components/Banner/Banner";
import CardPanelWhite from "../../components/CardPanelWhite/CardPanelWhite";
import HeroSection from "../../components/HeroSection/HeroSection";
import HrLine from "../../components/HrLine/HrLine";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import SearchBar from "../../components/SearchBar/SearchBar";
import SelectBar from "../../components/SelectBar/SelectBar";
import SubTitle from "../../components/Typography/SubTitle";
import Title from "../../components/Typography/Title";
import planetImage from "../../assets/images/planet.jpg";
import { useTranslation } from "react-i18next";
// import articles from './data';
import { REST } from "../../config/AxiosConfig";
import "./CentruX.css";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import HeroProject from "../../components/HeroProject/HeroProject";

function CentruX() {
  const { t } = useTranslation();
  const [center, setCenter] = useState({});
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(0);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const params = useParams();
  const centerId = params.id;

  const [currentPage, setCurrentPage] = useState(0);
  const [title, setTitle] = useState("");
  const [researchFields, setResearchFields] = useState([]);

  var searchBody = {
    keyword: title.length > 3 ? title : "",
    researchFields: researchFields.length > 0 ? researchFields.map((x) => x.id) : [],
    projectStatus: "APPROVED",
  };

  const getResearchCenter = async () => {
    await REST.get(`/organisation/research-center/${centerId}`)
      .then((response) => {
        setCenter(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getResearchCenterProjects = async () => {
    await REST.post(`/project/${centerId}/~?page=${page}&size=4`, searchBody)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    getResearchCenter();
    getResearchCenterProjects();
    // eslint-disable-next-line
  }, [currentPage, title, researchFields]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    console.log(newPage);
  };
  // todo ibm flex sans
  return (
    <Layout>
      <HeroSection
        backgroundImage={`data:image/jpeg;base64,${center?.heroImage}`}
        subtitle={center.title}
        showButton={false}
      />

      <div className="container m-auto">
        <section className="container-fluid flex flex-col sm:flex-row justify-content-center mt-5">
          <div>
            {lang === "ro" ? (
              <div className="description-label"> Scurta Descriere</div>
            ) : (
              <div className="description-label">Short Description</div>
            )}
            <div className="description">{center?.shortDescription}</div>
            {lang === "ro" ? (
              <div className="description-label"> Descriere</div>
            ) : (
              <div className="description-label">Description</div>
            )}

            <div className="description">{center?.description}</div>
          </div>
          <div className="d-flex flex-column gap-3 mx-auto">
            <CardPanelWhite
              title="Eveniment cu echipa"
              subtitle="12.01.2022 - 11:10 AM - Aula"
            />
            <CardPanelWhite
              title="Eveniment cu echipa"
              subtitle="12.01.2022 - 11:10 AM - Aula"
            />
            <CardPanelWhite
              title="Eveniment cu echipa"
              subtitle="12.01.2022 - 11:10 AM - Aula"
            />
          </div>
        </section>
        <HrLine />
        <section className="container mb-3">
          <Title title={"Proiectele " + center?.title} />
          <h1 className="title-component">
            {" "}
            <SubTitle subtitle="TUIASI HUB" uppercase />
          </h1>
        </section>

        {/*<section className="articles-section container">*/}
        {/*  <div className="container search-section d-flex flex-row ">*/}
        {/*    <SearchBar />*/}
        {/*    <SelectBar />*/}
        {/*  </div>*/}
        {/*  /!* <HrLine /> *!/*/}
        {/*  <ArticlePanel*/}
        {/*    articles={projects?.content}*/}
        {/*    itemsPerPage={4}*/}
        {/*    redirectUrl={"project"}*/}
        {/*  />*/}
        {/*  <Pagination*/}
        {/*    totalPages={projects.totalPages}*/}
        {/*    handlePageChange={handleChangePage}*/}
        {/*    currentPage={page + 1}*/}
        {/*  />*/}
        {/*</section>*/}
        <section className="articles-section container">
          <div className="container search-section d-flex flex-row ">
            <SearchBar
                handleInputChange={(event) => {
                  setTitle(event.target.value);
                }}
                targetEvent={title}
            />
            <SelectBar handleFieldChange={(event) => {
              setResearchFields(event);
            }}/>
          </div>

          <ArticlePanel
              articles={projects.content}
              itemsPerPage={4}
              redirectUrl={"project"}
          />

          <Pagination
              totalPages={projects.totalPages}
              handlePageChange={handleChangePage}
              currentPage={currentPage}
          />
        </section>
      </div>
      <div>
        <Banner
          title={t("subscribeNewsletter")}
          imageUrl={planetImage}
          height={530}
          showButton={true}
        />
      </div>

      <div className="container m-auto">
        <section className="container mb-3">
          <Title title={"Echipa Centrului de cercetare " + center?.title} />
          <h1 className="title-component">
            {" "}
            <SubTitle subtitle="TUIASI HUB" uppercase />
          </h1>
        </section>

        <div className="container d-flex flex-row images-container gap-3">
          {center?.team?.teamMembers.map((user, key) => (
            <ImageGallery
              id={user?.id}
              shortDescription={user?.shortDescription}
              profilePicture={user?.profilePicture}
              fullName={user?.fullName}
              key={user?.id}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default CentruX;
