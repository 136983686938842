import ButtonArrow from '../Button/ButtonArrow';
import HrLine from '../HrLine/HrLine';
import SubTitle from '../Typography/SubTitle';
import Title from '../Typography/Title';
import './ArticlePanel.css';

function ArticlePanel({ articles, redirectUrl }) {
  return (
    <div>
      <HrLine />
      {articles?.map((article, index) => (

        <div
          key={index}
          style={{
            backgroundColor: index % 2 === 0 ? 'white' : '#F6F8FA',
            padding: '10px',
          }}
        >
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
           
            <div className="p-4 content">
              <Title title={article?.title} />
              <SubTitle subtitle={article?.shortDescription} color=" #33418e" />
            </div>

            <ButtonArrow to={`/${redirectUrl}/${article.id}`} />

          </div>
        </div>
      ))}


    </div>
  );
}

export default ArticlePanel;
