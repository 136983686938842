import "./CentreCercetare.css";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";

import SunImage from "../../assets/images/SunTech.png";
import HeroImage from "../../assets/images/HeroDD.png";
import Numbers from "../../components/Numbers/Numbers";
import SearchBar from "../../components/SearchBar/SearchBar";
import SelectBar from "../../components/SelectBar/SelectBar";
import CardPanelWhite from "../../components/CardPanelWhite/CardPanelWhite";
import Pagination from "../../components/Pagination/Pagination";
import planetImage from "../../assets/images/planet.jpg";
import Banner from "../../components/Banner/Banner";
import React, { useEffect, useState } from "react";
import { REST } from "../../config/AxiosConfig";
import StatisticsSection from "../../components/StatisticsSection/StatisticsSection";
import CentersList from "../../components/CentersList/CentersList";
import { useTranslation } from "react-i18next";

function CentreCercetare() {
  const [centers, setCenters] = useState([]);
  const [researchFields, setResearchFields] = useState([]);
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(0);
  const [statistics, setStatistics] = useState({});
  const { t } = useTranslation();

  var searchBody = {
    keyword: title.length > 3 ? title : "",
    researchFields: researchFields.length > 0 ? researchFields.map((x) => x.id) : [],
  };

  const getStatistics = async () => {
    await REST.get(`/statistics/all`)
      .then((response) => {
        setStatistics(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getResearchCenters = async () => {
    await REST.post(
      `/organisation/research-center/search/~?page=${page}&size=4`,
      searchBody
    )
      .then((response) => {
        setCenters(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    // window.scrollTo(0, 0)
    getResearchCenters();
    getStatistics();
    // eslint-disable-next-line
  }, [page, searchBody.keyword, researchFields]);

  const handleChangePage = (e) => {
    setPage(e.selected);
    // scroll to the .tuiasi-article element 300 px over it for navbar
    const topValue = document.querySelector(".tuiasi-article").offsetTop - 300;

    window.scrollTo(0, topValue);
  };
  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle={t("researchCentersSubTitle")}
        showButton={true}
      />
      <div className="container m-auto">
        <StatisticsSection statistics={statistics} />
        <div className="container">
          <h2 className="centers-label">{t("researchCentersListLabel")}</h2>
          <br />
        </div>

        <div className="container search-section d-flex flex-row ">
          <SearchBar
            handleInputChange={(event) => {
              setTitle(event.target.value);
            }}
            targetEvent={title}
          />
          <SelectBar handleFieldChange={(event) => {
            setResearchFields(event);
          }}/>
        </div>

        <section className="container-fluid flex-lg-row flex-column tuiasi-article d-flex gap-3 mt-5">
          {centers.length !== 0 &&
            centers?.content[0] !== null &&
            centers?.content[0] !== undefined && (
              <CardPanelWhite
                title={centers?.content[0]?.title}
                subtitle={centers?.content[0]?.shortDescription}
                text={centers?.content[0]?.description}
                id={centers?.content[0]?.id}
                image={centers?.content[0]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
              />
            )}
        </section>
        <section className="container-fluid d-flex flex-lg-row-reverse flex-column gap-3 tuiasi-article mt-5">
          {centers.length !== 0 &&
            centers?.content[1] != null &&
            centers?.content[1] !== undefined && (
              <CardPanelWhite
                title={centers?.content[1]?.title}
                subtitle={centers?.content[1]?.shortDescription}
                text={centers?.content[1]?.description}
                id={centers?.content[1]?.id}
                image={centers?.content[1]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
                reverseOrder={true}
              />
            )}
        </section>
      </div>
      <div>
        <Banner
          title={t("subscribeNewsletter")}
          imageUrl={planetImage}
          height={300}
          showButton={true}
        />
      </div>
      <div className="container m-auto">
        <section className="container-fluid d-flex flex-row gap-3 tuiasi-article mt-5">
          {centers.length !== 0 &&
            centers?.content[2] !== null &&
            centers?.content[2] !== undefined && (
              <CardPanelWhite
                title={centers?.content[2]?.title}
                subtitle={centers?.content[2]?.shortDescription}
                text={centers?.content[2]?.description}
                id={centers?.content[2]?.id}
                image={centers?.content[2]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
              />
            )}
        </section>
        <section className="container-fluid d-flex flex-row-reverse gap-3 tuiasi-article mt-5">
          {centers.length !== 0 &&
            centers?.content[3] !== null &&
            centers?.content[3] !== undefined && (
              <CardPanelWhite
                title={centers?.content[3]?.title}
                subtitle={centers?.content[3]?.shortDescription}
                text={centers?.content[3]?.description}
                id={centers?.content[3]?.id}
                image={centers?.content[3]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
                reverseOrder={true}
              />
            )}
        </section>
        <div className="mt-4">
          <Pagination
            totalPages={centers.totalPages}
            handlePageChange={handleChangePage}
            currentPage={page}
          />
        </div>
      </div>

      {/*<Footer />*/}
    </Layout>
  );
}

export default CentreCercetare;
