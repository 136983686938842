import React, { useEffect, useState } from "react";
import bubblesImage from "../../assets/images/bubbles.png";
import GlassImage from "../../assets/images/Glasses2.png";
import HeroImage from "../../assets/images/HeroDD.png";
import ButtonArrow from "../../components/Button/ButtonArrow";
import CardPanel from "../../components/CardPanel/CardPanel";
import CardPanelWhite from "../../components/CardPanelWhite/CardPanelWhite";
import HeroSection from "../../components/HeroSection/HeroSection";
import "../../components/HeroSection/HeroSection.css";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import Layout from "../../components/Layout/Layout";
import Numbers from "../../components/Numbers/Numbers";
import Pagination from "../../components/Pagination/Pagination";
import { REST } from "../../config/AxiosConfig";
import "./Homepage.css";
import StatisticsSection from "../../components/StatisticsSection/StatisticsSection";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import { useTranslation } from "react-i18next";

export default function Homepage() {
  const [users, setUsers] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [articles, setArticles] = useState([]);
  const [researchFields, setResearchFields] = useState([]);
  const [page, setPage] = useState(0);
  const [lang, setLang] = useState(0);

  const { t } = useTranslation();

  const getUsers = async () => {
    await REST.get(`/users/~?page=${page}&size=8`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getStatistics = async () => {
    await REST.get(`/statistics/all`)
      .then((response) => {
        setStatistics(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getArticles = async () => {
    REST.get(`/article/homepage/~`)
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getResearchFields = async () => {
    await REST.get(`/organisation/research-field/~`)
      .then((response) => {
        setResearchFields(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    getUsers();
    getArticles();
    getResearchFields();
    getStatistics();
    // eslint-disable-next-line
  }, [page, lang]);

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (event) => {
    setPage(event.selected);
  };

  const smallList = researchFields.slice(0, 3);

  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle={t("joinCommunity")}
        showButton={true}
      />
      <div className="container m-auto">
        <StatisticsSection statistics={statistics} />

        <div className="container d-flex flex-row" id="cardPanel">
          <CardPanel />
          <div className="image-glass">
            <img style={{ width: "1000px" }} src={GlassImage} alt="Glasses" />
          </div>
        </div>

        <section className="section-circle container">
          <h2 className="card-panel-title">{t("researchAreas")}</h2>
          <div className="container">
            <div className="row gap-3">
              {smallList?.map((field, key) => (
                <CardPanelWhite
                  title={field.title}
                  width="auto"
                  height="254px"
                  hasButton={true}
                  redirectPath="research"
                  id=""
                />
              ))}
            </div>
          </div>
        </section>

        <ProjectSection bubblesImage={bubblesImage} />

        <section className="container section-people mt-5 mb-5">
          <h2 className="card-panel-title">{t("teamTitle")}</h2>
          <h2 className="mb-5 card-panel-title">
            {t("all")}
            <span className="card-panel-span"> A B C D E F G H I J K L M</span>
          </h2>
          <div className="container d-flex flex-row justify-content-center images-container gap-3">
            {users?.content?.map(
              (user, key) =>
                user.id != 4 && (
                  <ImageGallery
                    id={user?.id}
                    shortDescription={user?.shortDescription}
                    profilePicture={user?.profilePicture}
                    fullName={user?.firstName + " " + user?.lastName}
                    key={user?.id}
                  />
                )
            )}
          </div>
          <div className="container mt-5">{/* <ImageGallery /> */}</div>
          <h4 id="pagin" className="mt-5 ms-5">
            <Pagination
              totalPages={users.totalPages}
              handlePageChange={handleChangePage}
              currentPage={page}
            />
          </h4>
        </section>
        {articles.length !== 0 && (
          <section className="container section-articles mt-5 mb-5">
            <h2 className="card-panel-span mb-3">Articole de cercetare</h2>
            <div className="row">
              <div className="col">
                <CardPanelWhite
                  title={articles[0]?.title}
                  subtitle={articles[0]?.shortDescription}
                  text={articles[0]?.description}
                  image={articles[0]?.bigImage}
                  id={articles[0]?.id}
                  width="100%"
                  height="506px"
                  redirectPath="article"
                />
              </div>
            </div>
          </section>
        )}
        {articles.length !== 0 && (
          <section className="container">
            <div className="row">
              <div className="col">
                <CardPanelWhite
                  title={articles[1]?.title}
                  subtitle={articles[1]?.shortDescription}
                  text={articles[1]?.description}
                  image={articles[1]?.bigImage}
                  id={articles[1]?.id}
                  height="450px"
                  redirectPath="article"
                />
              </div>
              <div className="col">
                <CardPanelWhite
                  title={articles[2]?.title}
                  subtitle={articles[2]?.shortDescription}
                  text={articles[2]?.description}
                  image={articles[2]?.bigImage}
                  id={articles[2]?.id}
                  height="450px"
                  redirectPath="article"
                />
              </div>
              <div className="col">
                <CardPanelWhite
                  title={articles[3]?.title}
                  subtitle={articles[3]?.shortDescription}
                  text={articles[3]?.description}
                  image={articles[3]?.bigImage}
                  id={articles[3]?.id}
                  height="450px"
                  redirectPath="article"
                />
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
}
