import ButtonArrow from '../Button/ButtonArrow';
import './ArticleAsideBox.css';

function ArticleAsideBox({ title, subTitle, redirectPath }) {
  return (
    <div className="mb-5">
      <h2 className="display-6">{title}</h2>
      <p className="ps-1">{subTitle}</p>
      {redirectPath && (<ButtonArrow to={`${redirectPath}`} />)}
    </div>
  );
}

export default ArticleAsideBox;
