import React from "react";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroImage from "../../assets/images/HeroDD.png";
import HeroProject from "../../components/HeroProject/HeroProject";
import BubbleCard from "../../components/BubbleCard/BubbleCard";
import ResearchTopicsList from "../../components/ResearchTopicsList/ResearchTopicsList";
import FieldsOfApplicationList from "../../components/FieldsOfApplicationList/FieldsOfApplicationList";
import { useTranslation } from "react-i18next";

function FieldsOfApplication() {
  const { t } = useTranslation();
  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle={t("fieldsOfApplication")}
        showButton={false}
      />
      <div className="container m-auto">
        <BubbleCard
          title={t("fieldsOfApplication")}
          description={t('applicationAreasDescription')}
        />
        <FieldsOfApplicationList />
      </div>
    </Layout>
  );
}

export default FieldsOfApplication;
