export const navigationDictionary = {
    ro: {
      centers: "Centre de cercetare",
      research: "Subiecte de studiu",
      fieldsOfApplication: "Domenii de aplicare",
      labs: "Inovare digitala",
      patents: "Brevete",
      projects: "Proiecte"
    },
    en: {
      centers: "Research Centers",
      research: "Research Topics",
      fieldsOfApplication: "Fields of Application",
      labs: "Digital Labs",
      patents: "Patents",
      projects: "Projects"
    }
  };