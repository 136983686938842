import "./DigitalInnovation.css";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";
import SunImage from "../../assets/images/SunTech.png";
import Numbers from "../../components/Numbers/Numbers";
import SearchBar from "../../components/SearchBar/SearchBar";
import SelectBar from "../../components/SelectBar/SelectBar";
import CardPanelWhite from "../../components/CardPanelWhite/CardPanelWhite";
import Pagination from "../../components/Pagination/Pagination";
import planetImage from "../../assets/images/planet.jpg";
import Banner from "../../components/Banner/Banner";
import React, { useEffect, useState } from "react";
import { REST } from "../../config/AxiosConfig";
import StatisticsSection from "../../components/StatisticsSection/StatisticsSection";
import CentersList from "../../components/CentersList/CentersList";
import HeroProject from "../../components/HeroProject/HeroProject";
import { useTranslation } from "react-i18next";

function DigitalInnovation() {
  const { t } = useTranslation();
  const [labs, setLabs] = useState([]);
  const [researchFields, setResearchFields] = useState([]);
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(0);
  const [statistics, setStatistics] = useState({});

  var searchBody = {
    keyword: title.length > 3 ? title : "",
    researchFields: researchFields.length > 0 ? researchFields.map((x) => x.id) : [],
    digitalInovationStatus: "APPROVED",
  };

  const getStatistics = async () => {
    await REST.get(`/statistics/all`)
      .then((response) => {
        setStatistics(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getLabs = async () => {
    await REST.post(`/labs/~?page=${page}&size=4&sort=`, searchBody)
      .then((response) => {
        setLabs(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    // window.scrollTo(0, 0)
    getLabs();
    getStatistics();
    // eslint-disable-next-line
  }, [page, title, researchFields]);

  const handleChangePage = (e) => {
    setPage(e.selected);
    // scroll to the .tuiasi-article element 300 px over it for navbar
    const topValue = document.querySelector(".tuiasi-article").offsetTop - 300;

    window.scrollTo(0, topValue);
  };
  return (
    <Layout>
      <HeroSection
        backgroundImage={SunImage}
        subtitle={t("researchCentersSubTitle")}
        showButton={false}
      />
      <StatisticsSection statistics={statistics} />
      <div className="container">
        <h2 className="centers-label">{t("labListTitle")}</h2>
        <br />
      </div>

      <div className="container search-section d-flex flex-row ">
        <SearchBar
          handleInputChange={(event) => {
            setTitle(event.target.value);
          }}
          targetEvent={title}
        />
          <SelectBar handleFieldChange={(event) => {
              setResearchFields(event);
          }}/>
      </div>

      <div className="container m-auto">
        <section className="container-fluid flex-lg-row flex-column d-flex gap-3 tuiasi-article mt-5">
          {labs.length !== 0 &&
            labs?.content[0] !== null &&
            labs?.content[0] !== undefined && (
              <CardPanelWhite
                title={labs?.content[0]?.title}
                subtitle={labs?.content[0]?.shortDescription}
                text={labs?.content[0]?.description}
                id={labs?.content[0]?.id}
                image={labs?.content[0]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
              />
            )}
        </section>
        <section className="container-fluid d-flex flex-lg-row-reverse flex-column gap-3 tuiasi-article mt-5">
          {labs.length !== 0 &&
            labs?.content[1] != null &&
            labs?.content[1] !== undefined && (
              <CardPanelWhite
                title={labs?.content[1]?.title}
                subtitle={labs?.content[1]?.shortDescription}
                text={labs?.content[1]?.description}
                id={labs?.content[1]?.id}
                image={labs?.content[1]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
                reverseOrder={true}
              />
            )}
        </section>
      </div>

      <div>
        <Banner
          title={t("subscribeNewsletter")}
          imageUrl={planetImage}
          height={500}
          showButton={true}
        />
      </div>

      <div className="container m-auto">
        <section className="container-fluid d-flex flex-row gap-3 tuiasi-article mt-5">
          {labs.length !== 0 &&
            labs?.content[2] !== null &&
            labs?.content[2] !== undefined && (
              <CardPanelWhite
                title={labs?.content[2]?.title}
                subtitle={labs?.content[2]?.shortDescription}
                text={labs?.content[2]?.description}
                id={labs?.content[2]?.id}
                image={labs?.content[2]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
              />
            )}
        </section>
        <section className="container-fluid d-flex flex-row-reverse gap-3 tuiasi-article mt-5">
          {labs.length !== 0 &&
            labs?.content[3] !== null &&
            labs?.content[3] !== undefined && (
              <CardPanelWhite
                title={labs?.content[3]?.title}
                subtitle={labs?.content[3]?.shortDescription}
                text={labs?.content[3]?.description}
                id={labs?.content[3]?.id}
                image={labs?.content[3]?.heroImage}
                width="100%"
                height="506px"
                redirectPath="center"
                reverseOrder={true}
              />
            )}
        </section>
      </div>

      <div className="mt-4">
        <Pagination
          totalPages={labs.totalPages}
          handlePageChange={handleChangePage}
          currentPage={page}
        />
      </div>

      {/*<Footer />*/}
    </Layout>
  );
}

export default DigitalInnovation;
