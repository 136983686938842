import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import brainImage from "../../assets/images/brainAI.png";
import heroProjectImage from "../../assets/images/hero-project.png";
import planetImage from "../../assets/images/planet.jpg";
import ArticlePanel from "../../components/ArticlePanel/ArticlePanel";
import Banner from "../../components/Banner/Banner";
import CardPanelWhite from "../../components/CardPanelWhite/CardPanelWhite";
import DocumentContact from "../../components/DocumentContact/DocumentContact";
import HeroProject from "../../components/HeroProject/HeroProject";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import Layout from "../../components/Layout/Layout";
import SocialLink from "../../components/SocialLink/SocialLink";
import SubTitle from "../../components/Typography/SubTitle";
import Title from "../../components/Typography/Title";
import { baseUrl, REST } from "../../config/AxiosConfig";
import "./ProjectPage.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import { useTranslation } from "react-i18next";

function ProjectPage() {
  const { t } = useTranslation();
  const params = useParams();
  const projectId = params.id;
  const [project, setProject] = useState({});
  const [articles, setArticles] = useState([]);
  const [documents, setDocuments] = useState([]);

  const getProject = async () => {
    await REST.get(`/project/${projectId}`)
      .then((response) => {
        setProject(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getDocuments = async () => {
    await REST.get(`/project/${projectId}/professinalDocuments`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getArticles = async () => {
    await REST.get(`/article/getAllArticlesByProjectId/${projectId}`)
      .then((response) => {
        setArticles(response.data.content);
      })
      .catch((error) => {
        // todo handle errors
      });
  };
  console.log(articles, "ART");

  useEffect(() => {
    window.scrollTo(0, 0);
    getProject();
    getArticles();
    getDocuments();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {project?.heroImage !== null ? (
        <HeroSection
          backgroundImage={`data:image/jpeg;base64,${project?.heroImage}`}
          subtitle={project?.title}
          showButton={false}
        />
      ) : (
        <HeroSection
          backgroundImage={heroProjectImage}
          subtitle="Welcome to the Science Center"
          showButton={false}
        />
      )}
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <img
              src={brainImage}
              alt="brain"
              className="img-fluid"
              style={{
                width: "200px",
                borderRadius: "50%",
                marginTop: "-4rem",
                position: "relative",
                zIndex: "3",
              }}
            />
          </div>
          <div className="col-9">
            <Title title={project?.title} />
            <SubTitle subtitle={project?.shortDescription} />

            <p dangerouslySetInnerHTML={{ __html: project?.description }} />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <h4 className="display-6">White Paper</h4>

            {documents?.map((doc, key) => (
              <a
                style={{ textDecoration: "none" }}
                href={`${baseUrl}/project/${doc.id}/download`}
                target="_blank"
              >
                <DocumentContact
                  titleDoc={doc.fileName}
                  iconDoc={doc.fileType}
                  dateDoc="2021-05-05T12:00:00Z"
                  fileType={doc?.fileType}
                />
              </a>
            ))}
          </div>
          <div className="col">
            <h4 className="display-6">Contact:</h4>
            <p className="fw-bold">{project?.contactPerson}</p>
          </div>

          <div className="col">
            <h4 className="display-6">Social Media</h4>
            <div className="icons-wrapper mb-5">
              {project?.socialLinks?.map((socialLink) => (
                <SocialLink
                  key={socialLink.id}
                  url={socialLink.url}
                  socialLinkType={socialLink.socialLinkType}
                />
              ))}
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <h2 className="card-panel-title">Echipa de cercetare a </h2>
        <h2 className="card-panel-title"> Proiectului</h2>
        <br />

        <div className="container d-flex flex-row images-container ">
          {project?.team?.teamMembers?.map((user, key) => (
            <ImageGallery
              id={user?.id}
              shortDescription={user?.shortDescription}
              profilePicture={user?.profilePicture}
              fullName={user?.fullName}
              key={user?.id}
            />
          ))}
        </div>
      </div>

      <div>
        <Banner
          title={t("subscribeNewsletter")}
          imageUrl={planetImage}
          height={530}
          showButton={true}
        />
      </div>
      <hr className="mt-5 container" />

      {articles !== undefined && articles?.length !== 0 && (
        <div>
          <section className="container section-articles mt-5 mb-5">
            <h2 className="card-panel-span mb-3">Articole de cercetare</h2>
            <div className="row">
              <div className="col">
                <CardPanelWhite
                  title={articles[0]?.title}
                  subtitle={articles[0]?.shortDescription}
                  text={articles[0]?.description}
                  image={articles[0]?.bigImage}
                  id={articles[0]?.id}
                  width="100%"
                  height="506px"
                  redirectPath="article"
                />
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col">
                <CardPanelWhite
                  title={articles[1]?.title}
                  subtitle={articles[1]?.shortDescription}
                  text={articles[1]?.description}
                  image={articles[1]?.bigImage}
                  id={articles[1]?.id}
                  height="450px"
                  redirectPath="article"
                />
              </div>
              <div className="col">
                <CardPanelWhite
                  title={articles[2]?.title}
                  subtitle={articles[2]?.shortDescription}
                  text={articles[2]?.description}
                  image={articles[2]?.bigImage}
                  id={articles[2]?.id}
                  height="450px"
                  redirectPath="article"
                />
              </div>
              <div className="col">
                <CardPanelWhite
                  title={articles[3]?.title}
                  subtitle={articles[3]?.shortDescription}
                  text={articles[3]?.description}
                  image={articles[3]?.bigImage}
                  id={articles[3]?.id}
                  height="450px"
                  redirectPath="article"
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}

export default ProjectPage;
