import Layout from '../../components/Layout/Layout';
import './LibraryPage.css';

import SearchBarBig from '../../components/SearchBarBig/SearchBarBig';

function LibraryPage() {
  return (
    <Layout>
      <div className="hero-library">
        <div className="hero-library-container">
          <div className="hero-content-library">
            <h1 className="titleHero">
              Let's have a moment <span>of science</span>
            </h1>
          </div>
          <p className="hero-library-subtitle">
            Cauta un topic de cercetare si vezi tot ce am publicat noi pe acest
            domeniu
          </p>
          {/* <div className="d-flex search-container">
            <SearchBarBig />
          </div> */}
        </div>
        <SearchBarBig />

      </div>
    </Layout>
  );
}

export default LibraryPage;
