import "./SearchBar.css";
import { useTranslation } from "react-i18next";
// import { useState } from 'react';

function SearchBar(props) {
  // const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const { handleInputChange = () => {}, targetEvent = "" } = props;

  return (
    <form className="d-flex w-75">
      <input
        className="form-control me-2"
        type="search"
        placeholder={t("searchByTitle")}
        aria-label="Search"
        value={targetEvent}
        onChange={handleInputChange}
      />
    </form>
  );
}

export default SearchBar;
