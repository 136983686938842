import React from "react";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroImage from "../../assets/images/HeroDD.png";
import { useTranslation } from "react-i18next";
import BubbleCard from "../../components/BubbleCard/BubbleCard";

const Patents = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle={t("patents")}
        showButton={false}
      />
      <div className="container m-auto">
        <BubbleCard title={t("patents")} description={t("robotTeamPlanning")} />
      </div>
    </Layout>
  );
};

export default Patents;
