import { changeLanguage } from 'i18next'
import React, { useState } from 'react'

const LanguageDropdown = ({ languageOptions }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedFlag, setSelectedFlag] = useState(
    localStorage.getItem('lang') === 'en'
      ? languageOptions[0].flagimg
      : languageOptions[1].flagimg
  )

  const toggleDropdown = () => {
    setIsDropdownOpen((prevOpen) => !prevOpen)
    console.log(isDropdownOpen)
  }

  const selectListItem = (event) => {
    handleLanguageChange(event)
    setIsDropdownOpen(false)
    setSelectedFlag(event.target.src)
  }

  const handleLanguageChange = (event) => {
    const languageCode = event.target.id || event.target.dataset.language; 
    changeLanguage(languageCode);
    // todo remove these while implementing i18n
    localStorage.setItem('lang', event.target.id)
    window.location.reload(true)
    // todo remove these while implementing i18n
  }

  return (
    <div style={{ position: 'relative' }}>
      <button
        style={{
          padding: '0px',
          background: 'none',
          border: 'none',
        }}
        onClick={toggleDropdown}
      >
        <img src={selectedFlag} height='25' width='25' alt='nope' />
      </button>
      {isDropdownOpen && (
        <ul className='language-options'>
          {languageOptions.map((lang) => (
            <li key={lang.id} style={{ listStyle: 'none' }}>
              <img
                onClick={selectListItem}
                src={lang.flagimg}
                height='25'
                width='25'
                alt='flagpic'
                id={lang.id}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default LanguageDropdown
