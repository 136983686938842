// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import ArticlePage from "./pages/ArticlePage/ArticlePage";
import ArticlesList from "./pages/ArticlePage/ArticlesList";
import CentreCercetare from "./pages/Centre-cercetare/CentreCercetare";
import CentruX from "./pages/Centru-1/CentruX";
import Contact from "./pages/Contact/Contact";
import Members from "./pages/Contact/Members";
import Homepage from "./pages/Homepage/Homepage";
import LibraryPage from "./pages/LibraryPage/LibraryPage";
import ProjectsList from "./pages/project/ProjectsList";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import ResearchTopics from "./pages/ResearchTopics/ResearchTopics";
import FieldsOfApplication from "./pages/FieldsOfApplication/FieldsOfApplication";
import DigitalInnovation from "./pages/DigitalInnovation/DigitalInnovation";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

import ro from "./lib/translations/ro.json";
import en from "./lib/translations/en.json";
import Patents from "./pages/Patents/Patents";
import ContactInformation from "./pages/ContactInformation/ContactInformation";
import AboutPage from "./pages/AboutPage/About";

i18n
  .use(initReactI18next) // Initialize react-i18next plugin
  .init({
    resources: {
      en: { translation: en },
      ro: { translation: ro },
    },
    lng: localStorage.getItem("lang") || navigator.language.slice(0, 2), // Set initial language
    interpolation: {
      escapeValue: false, // Prevent escaping of HTML entities
    },
  });

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/centers" element={<CentreCercetare />} />
        <Route path="/center/:id" element={<CentruX />} />
        <Route path="/projects" element={<ProjectsList />} />
        <Route path="/members" element={<Members />} />
        <Route path="/patents" element={<Patents />} />
        <Route path="/contact/:id" element={<Contact />} />
        <Route path="/articles" element={<ArticlesList />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="/library" element={<LibraryPage />} />
        <Route path="/project/:id" element={<ProjectPage />} />
        <Route path="/research" element={<ResearchTopics />} />
        <Route path="/fieldsOfApplication" element={<FieldsOfApplication />} />
        <Route path="/labs" element={<DigitalInnovation />} />
        <Route path="/contact" element={<ContactInformation />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </I18nextProvider>
  );
}

export default App;
