import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleAsideBox from '../../components/ArticleAsideBox/ArticleAsideBox';
import DocumentContact from '../../components/DocumentContact/DocumentContact';
import HeroSection from '../../components/HeroSection/HeroSection';
import Layout from '../../components/Layout/Layout';
import SubTitle from '../../components/Typography/SubTitle';
import Title from '../../components/Typography/Title';
import { REST } from '../../config/AxiosConfig';
import './ArticlePage.css';


function ArticlePage() {
  const [article, setArticle] = useState({});
  const [documents, setDocuments] = useState([]);
  const params = useParams();
  const articleId = params.id;
  const baseUrl = "https://core-tuiasi.blacksquare.solutions/api"

  const getArticle = async () => {
    await REST.get(`/article/${articleId}`)
      .then((response) => {
        setArticle(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getArticleDocuments = async () => {
    await REST.get(`/article/${articleId}/getAllDocuments`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    getArticle();
    getArticleDocuments();
    // eslint-disable-next-line
  }, []);


  return (
    <Layout>

      <HeroSection
        backgroundImage={`data:image/jpeg;base64,${article?.bigImage}`}
      // subtitle="Poti deveni oricand membru al comunitatii stiintifice TUIASI HUB"
      // showButton={true}
      />
      <div>

        <div className="container mt-5 article-container">
          <div key={article?.id} className="row gap-5">
            <div className="col-8 ">
              <div className="mb-5">
                <Title title={article?.title} />
                <SubTitle subtitle={article?.subtitle} color=" #33418e" />
              </div>
              <p dangerouslySetInnerHTML={{ __html: article?.description }} />

            </div>

            <div className="col">
              <ArticleAsideBox title="Proiect" subTitle={article?.projectName} redirectPath={`/project/${article?.projectId}`} />
              <ArticleAsideBox title="Echipa" subTitle={article?.team?.name} />
              <ArticleAsideBox
                title="Data Publicarii"
                subTitle={article?.publishDate}
              />
              <hr style={{ width: '90%' }} />

              <div className="mb-5">
                <h4 className="display-6">Download</h4>
                {documents?.map((doc, key) => (

                  <a style={{ textDecoration: 'none' }}
                    href={`${baseUrl}/article/${doc.id}/download`}
                    target="_blank"
                  >
                    <DocumentContact
                      titleDoc={doc.fileName}
                      iconDoc={doc.fileType}
                      dateDoc="2021-05-05T12:00:00Z"
                      fileType={doc?.fileType}
                    />
                  </a>


                ))}
                {/* <DocumentContact
                  titleDoc="Diploma"
                  iconDoc={pdfImage}
                  dateDoc="2021-05-05T12:00:00Z"
                /> */}
              </div>

              <h4 className="display-6">Contact:</h4>
              <h5 className="fw-bold mb-0 mt-4">Email</h5>
              <p>{article?.email}</p>

              <hr style={{ width: '90%' }} />

              {/* <h4 className="display-6">Topic de cercetare</h4>
              <h5 className="fw-bold mb-0 mt-4">Biotehnologie</h5>
              <p>topic1, topic 2, topic 3</p>

              <h5 className="fw-bold mb-0 mt-4">Tehnologie</h5>
              <p>topic1, topic 2, topic 3</p>

              <h5 className="fw-bold mb-0 mt-4">Apa</h5>
              <p>topic1, topic 2, topic 3</p>

              <hr style={{ width: '90%' }} />

              <div className="mb-5">
                <h4 className="display-6">Social Media</h4>
                {objectContact.socialLinks.map((socialLink) => (
                  <SocialLink
                    key={socialLink.id}
                    url={socialLink.url}
                    socialLinkType={socialLink.socialLinkType}
                    icon={socialLink.icon}
                  />
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ArticlePage;
