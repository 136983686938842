import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleInfo, faSearch} from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { navigationDictionary } from '../../lib/utils'

import IconUser from '../../assets/images/Avatar.png'
import Logo from '../../assets/images/Logo-Asachi.png'
import MobileLogo from '../../assets/images/Logo-Asachi2.png'

import './Navbar.css'
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown'
import { useTranslation } from 'react-i18next'

export default function Navbar() {
  const [clipPath, setClipPath] = useState('')
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [lang, setLang] = useState(localStorage.getItem("lang"));

  const languageOptions = [
    {
      id: 'en',
      name: 'English',
      flagimg:
        'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg',
    },
    {
      id: 'ro',
      name: 'Romanian',
      flagimg: 'https://freesvg.org/img/Anonymous-Flag-of-Romania.png',
    },
  ]


  const { t } = useTranslation();

  const navigationLinks = [
    { path: '/centers', textKey: 'centers' },
    { path: '/research', textKey: 'research' },
    { path: '/fieldsOfApplication', textKey: 'fieldsOfApplication' },
    { path: '/labs', textKey: 'labs' },
    { path: '/patents', textKey: 'patents' },
    { path: '/projects', textKey: 'projects' },
  ];


  useEffect(() => {
    updateClipPath()
    window.addEventListener('resize', updateClipPath)

    return () => {
      window.removeEventListener('resize', updateClipPath)
    }
  }, [])

  // set the clip path radius to the diagonal of the screen
  const updateClipPath = () => {
    const vw = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    const vh = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    const radius = Math.sqrt(vw * vw + vh * vh) + 100
    setClipPath(`circle(${radius}px at 90% -10%)`)
  }

  const handleLanguageChange = (event) => userLanguageChange(event)
  const userLanguageChange = (event) => {
    localStorage.setItem('lang', event.target.id)
    window.location.reload(true)
  }
  const hamburger = document.querySelector('.hamburger')
  const navLinks = document.querySelector('.nav-links')
  const links = document.querySelectorAll('.nav-links li')

  const toggleHamburger = () => {
    navLinks.classList.toggle('open')
    hamburger.classList.toggle('toggle')
    links.forEach((link) => {
      link.classList.toggle('fade')
    })
    document.body.classList.toggle('no-scroll')
    setIsNavOpen(!isNavOpen)
  }
  return (
    <>
      <nav style={{ zIndex: '999', top: '0' }}>
        <div className='logo'>
          <a style={{ textDecoration: 'none', height: "100%" }} className="" href='/'>
            <MediaQuery minWidth={10}>
              <img src={Logo} alt='Logo'/>
            </MediaQuery>
            <MediaQuery maxWidth={10}>
              <p
                style={{
                  paddingLeft: '10px',
                  fontWeight: 'bolder',
                  fontSize: '18px',
                  textAlign: 'left',
                  color: 'white',
                }}
              >
                Gheorghe Asachi <br /> Technical University of Iași
              </p>
            </MediaQuery>
          </a>
        </div>
        <div class='hamburger' onClick={toggleHamburger}>
          <div class='line1'></div>
          <div class='line2'></div>
          <div class='line3'></div>
        </div>
        <ul class='nav-links' style={isNavOpen ? { clipPath: clipPath } : {}}>
          {navigationLinks.map((link) => (
            <li key={link.path}>
              <Link to={link.path} className="nav-link text-white">
                {t(link.textKey)}
              </Link>
            </li>
          ))}
          <li className='nav-item nav-item-icon'>
            <form class='search'>
              <input
                type='text'
                id='search'
                class='search__input'
                placeholder='Search....'
              />
              <button type='button' class='search__button'>
                <FontAwesomeIcon icon={faSearch} style={{ color: '#ffffff' }} />
              </button>
            </form>
          </li>
          <Link to='/members' className='nav-item nav-item-icon'>
            <FontAwesomeIcon icon={faUsers} style={{ color: '#ffffff' }} />
          </Link>
          <Link to='/contact' className='nav-item nav-item-icon'>
            <FontAwesomeIcon icon={faMessage} style={{ color: '#ffffff' }} />
          </Link>
          <Link to='/about' className='nav-item nav-item-icon'>
            <FontAwesomeIcon icon={faCircleInfo} style={{ color: '#ffffff' }} />
          </Link>
          <a
            href='https://www.research-hub-backend.tuiasi.ro'
            target='_blank'
            className='nav-item nav-item-icon'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faUser} style={{ color: '#ffffff' }} />
          </a>
          <li className='nav-item nav-item-icon'>
            <LanguageDropdown languageOptions={languageOptions} />
          </li>
        </ul>
      </nav>
    </>
  )
}
