import {useTranslation} from "react-i18next";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroImage from "../../assets/images/HeroDD.png";
import React from "react";


const AboutPage = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <HeroSection
                backgroundImage={HeroImage}
                subtitle={t("aboutTitle")}
                showButton={false}
            />
            <div className="contact-container ">
                <h2>{t("aboutContentTitle")}</h2>
                <div className="contact-section" style={{textIndent: 12}}>
                    <p>
                        {t("aboutContent1")}
                    </p>
                    <p>
                        {t("aboutContent2")}
                    </p>
                    <p>
                        {t("aboutContent3")}
                    </p>
                </div>
            </div>

            <div className="contact-container">
                <h2>{t("aboutContentUniversityTitle")}</h2>
                <div className="contact-section" style={{textIndent: 12}}>
                    <p>
                        {t("aboutContentUniversity1")}
                    </p>
                    <p>
                        {t("aboutContentUniversity2")}
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default AboutPage;