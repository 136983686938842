import React from "react";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroImage from "../../assets/images/HeroDD.png";
import HeroProject from "../../components/HeroProject/HeroProject";
import BubbleCard from "../../components/BubbleCard/BubbleCard";
import ResearchTopicsList from "../../components/ResearchTopicsList/ResearchTopicsList";
import { useTranslation } from "react-i18next";

function ResearchTopics() {
  const { t } = useTranslation();
  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle={`${t("studyTopics")} - ${t("thingsThatInterestUs")}`}
        showButton={false}
      />
      <div className="container m-auto">
        <BubbleCard
          title={t("studyTopics")}
          description={t("studyTopicsDescription")}
        />
        <ResearchTopicsList />
      </div>
    </Layout>
  );
}

export default ResearchTopics;
