import React, { useState } from "react";
import "./ImageGallery.css";
import ButtonArrow from "../Button/ButtonArrow";
import profilePic from "../../assets/images/profilePicture.jpg";
import {Link, redirect} from "react-router-dom";

function ImageGallery({ id, shortDescription, profilePicture, fullName }) {
  const base64Image = `data:image/jpeg;base64,${profilePicture}`;

  const [isExpanded, setExpanded] = useState(false);

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };
  let divStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: 300,
    height: 400,
    borderRadius: 10,
  };
  if (profilePicture) {
    divStyle = { ...divStyle, backgroundImage: `url(${base64Image})` };
  } else {
    divStyle = { ...divStyle, backgroundImage: `url(${profilePic})` };
  }

  return (

    <div
      style={divStyle}
      className="d-flex align-items-end z-3 position-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
        <Link className="text-decoration-none w-100 position-absolute start-0 end-0 top-0 bottom-0" to={`/contact/${id}`}></Link>
        {/* <img src={imageMen1} alt="man" /> */}
        <div className={`blur-container ${isExpanded ? "expanded" : "normal"} d-flex flex-column align-items-baseline justify-content-between`}>
          <h4>{fullName}</h4>
          <p>{shortDescription}</p>
          <div className="align-self-end">
              {isExpanded && (
                <ButtonArrow
                  to={`/contact/${id}`}
                  fontSize="20px"
                  color="white"
                  textColor="#FFFFFF"
                />
              )}
          </div>
          {/* <img src={`data:image/jpeg;base64,${profilePicture}`} alt="poza" /> */}
        </div>
    </div>

  );
}

export default ImageGallery;
