import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticlePanel from "../../components/ArticlePanel/ArticlePanel";
import ContactSection from "../../components/ContactSection/ContactSection";
import HrLine from "../../components/HrLine/HrLine";
import Layout from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import SearchBar from "../../components/SearchBar/SearchBar";
import SelectBar from "../../components/SelectBar/SelectBar";
import Title from "../../components/Typography/Title";
import { REST } from "../../config/AxiosConfig";
import "./Contact.css";
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  const params = useParams();
  const contactId = params.id;
  const [user, setUser] = useState({});
  const [documents, setDocuments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(0);
  const [title, setTitle] = useState("");

  var searchBody = {
    keyword: "",
    researchFields: [],
    projectStatus: "APPROVED",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    console.log(newPage);
  };

  const getUserProfessionalDocuments = async () => {
    await REST.get(`/users/${contactId}/professinalDocuments`)
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };
  const getUserProjects = async () => {
    await REST.post(`/project/researcher/${contactId}/~`, searchBody)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  const getUserProfile = async () => {
    REST.get(`/users/${contactId}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        // todo handle errors
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserProfessionalDocuments(contactId);
    getUserProfile();
    getUserProjects();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <ContactSection user={user} documents={documents} />
      <HrLine />
      <div className="container">
        <h2 className="centers-label">{t("researcherProjectsLabel")} </h2>
        <span />
        <h2 className="centers-label">TUIASI HUB </h2>
        <br />
      </div>

      <section className="articles-section container">
        <div className="container search-section d-flex flex-row ">
          <SearchBar
            handleInputChange={(event) => {
              setTitle(event.target.value);
            }}
            targetEvent={title}
          />
          <SelectBar />
        </div>
        <ArticlePanel
          articles={projects.content}
          itemsPerPage={4}
          redirectUrl={"project"}
        />
        <Pagination
          totalPages={projects.totalPages}
          handlePageChange={handleChangePage}
          currentPage={page + 1}
        />
      </section>
    </Layout>
  );
}

export default Contact;
