import "./ButtonArrow.css";
import arrowIcon from "../../assets/icons/Arrow.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ButtonArrow({ textColor, fontSize, to, buttonText }) {
  const { t } = useTranslation();
  const buttonStyle = {
    color: textColor,
    fontSize: fontSize,
    textColor: textColor,
  };
  return (
    <div className="container-button-arrow align-self-end md-align-self-center">
      <Link className="text-decoration-none" to={to}>
        <button className="button-arrow d-flex align-items-center" style={buttonStyle}>
         {buttonText !== undefined ? buttonText : (buttonText = t("more"))}
          <img src={arrowIcon} alt="arrow icon" className="icon-arrow" />
        </button>
      </Link>
    </div>
  );
}

export default ButtonArrow;
