import React from "react";
import MediaQuery from "react-responsive";

import "./Footer.css";
import Logo from "../../assets/images/Logo-Asachi.png";
import MobileLogo from "../../assets/images/Logo-Asachi2.png";
import ColumnFooter from "../ColumnFooter/ColumnFooter";
import { useTranslation } from "react-i18next";

const data = {
  aboutTitle: [
    { linktext: "learnMoreAboutTUIasi", apiUrl: "#" },
    { linktext: "missionVision", apiUrl: "#" },
  ],
  tosTitle: [
    { linktext: "contactSupport", apiUrl: "#" },
    { linktext: "accessibility", apiUrl: "#" },
    { linktext: "nondiscriminationPolicy", apiUrl: "#" },
    { linktext: "ethicsReporting", apiUrl: "#" },
    { linktext: "privacyPolicy", apiUrl: "#" },
    { linktext: "terms", apiUrl: "#" },
    { linktext: "feedback", apiUrl: "#" },
  ],
  investorsTitle: [
    { linktext: "join", apiUrl: "#" },
    { linktext: "renew", apiUrl: "#" },
    { linktext: "benefits", apiUrl: "#" },
    { linktext: "ieeeColabratec", apiUrl: "#" },
    { linktext: "reportCybersecurity", apiUrl: "#" },
    { linktext: "contact", apiUrl: "#" },
  ],
  studentsTeachersTitle: [
    { linktext: "join", apiUrl: "#" },
    { linktext: "renew", apiUrl: "#" },
    { linktext: "benefits", apiUrl: "#" },
    { linktext: "ieeeColabratec", apiUrl: "#" },
    { linktext: "reportCybersecurity", apiUrl: "#" },
    { linktext: "contact", apiUrl: "#" },
  ],
  socialMediaPressTitle: [
    { linktext: "join", apiUrl: "#" },
    { linktext: "publishing", apiUrl: "#" },
    { linktext: "benefits", apiUrl: "#" },
    { linktext: "societies", apiUrl: "#" },
    { linktext: "council", apiUrl: "#" },
    { linktext: "standards", apiUrl: "#" },
    { linktext: "careers", apiUrl: "#" },
    { linktext: "volunteers", apiUrl: "#" },
  ],
};

function Footer() {
  const { t } = useTranslation();
  return (
    <div class="container-fluid text-white mt-5" id="footer-container">
      <footer class="py-5">
        <div className="mb-4 d-lg-block d-flex justify-content-center">
          <MediaQuery minWidth={200}>
            <img src={Logo} alt="Logo" style={{width: "30rem"}} className="align-content-center"/>
          </MediaQuery>
          <MediaQuery maxWidth={200}>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bolder",
                textAlign: "left",
                color: "white",
              }}
            >
              Gheorghe Asachi <br /> Technical University of Iași
            </p>
          </MediaQuery>
        </div>
        <div className="row">
          {Object.keys(data).map((key, index) => (
            <div
              className="col mb-3 d-flex flex-column align-items-start"
              key={index}
            >
              <ColumnFooter
                title={t(key)}
                data={data[key].map(({ linktext, apiUrl }) => ({
                  linktext: t(linktext),
                  apiUrl,
                }))}
              />
            </div>
          ))}
        </div>
      </footer>
    </div>
  );
}

export default Footer;
