import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {REST} from "../../config/AxiosConfig";
import {Autocomplete, Checkbox, InputLabel, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import _ from "lodash";

const SelectBar = (props) => {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [researchFields, setResearchFields] = useState([]);
  const methods = useForm();
  const { reset, watch, control, onChange, handleSubmit, formState, getValues } = methods;
  const { isValid, dirtyFields, errors } = formState;
  const form = watch();

    const { handleFieldChange = event => {}, targetEvent = "" } = props;

  useEffect(() => {
    getResearchFields();
  }, []);

  const getResearchFields = async () => {
    await REST.get(`organisation/research-field/~`)
        .then((response) => {
          setResearchFields(response.data);
        })
        .catch((error) => {
          // todo handle errors
        });
  };

  const researchFieldsOptions = researchFields?.map((i) => {
    return {
      id: i.id,
      title: i.title,
    };
  });


  const toggleDropdown = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (option) => {
    // Handle checkbox change logic here
    console.log(`Checkbox for ${option} is toggled`);
  };

  return (
    <div className="dropdown w-25 font-weight-normal ">
          <div className="mt-32">
            <Controller
                control={control}
                name="researchFields"
                render={({field: {onChange, value}}) => (
                    <Autocomplete
                        multiple
                        id="researchFields"
                        className=""
                        options={researchFieldsOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                          return option?.title ? option.title : '';
                        }}
                        renderOption={(_props, option, {selected}) => (
                            <li {..._props}>
                              <Checkbox style={{marginRight: 8}} checked={selected}/>
                              {option.title}
                            </li>
                        )}
                        value={
                          value ? value.map((id) => _.find(researchFieldsOptions, {id})) : []
                        }
                        onChange={(event, newValue) => {
                          onChange(newValue.map((item) => item.id));
                            handleFieldChange(newValue);
                        }}
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select options"
                                label="Select options"
                            />
                        )}
                    />
                )}
            />
          </div>
    </div>
  );
};

export default SelectBar;
