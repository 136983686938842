import React from "react";
import Numbers from "../Numbers/Numbers";
import { useTranslation } from "react-i18next";

const StatisticsSection = ({ statistics }) => {
  const { t } = useTranslation();
  return (
    <div
      className="container row justify-content-between mt-5 mx-auto"
      id="wrapper-numbers"
    >
      <Numbers
        text={t("researchProjects")}
        number={statistics?.projectsTotal}
      />
      <Numbers text={t("articles")} number={statistics?.articlesTotal} />
      <Numbers text={t("researchTeams")} number={statistics?.teamsTotal} />
      <Numbers
        text={t("researchCenters")}
        number={statistics?.researchesCenterTotal}
      />
    </div>
  );
};

export default StatisticsSection;
