import React from "react";
import ButtonArrow from "../Button/ButtonArrow";

import "./ProjectSection.css";
import { useTranslation } from "react-i18next";

const ProjectSection = ({ bubblesImage }) => {
  const { t } = useTranslation();
  return (
    <section className="container-fluid d-flex justify-content-center mb-5 flex-column flex-lg-row">
      <img
        src={bubblesImage}
        alt="bubbles and forms"
        className="project-section-image"
      />
      <article className="article-card-bubble ml-lg-n7">
        <div style={{ marginBottom: 50 }}>
          <h2 className="card-panel-title">{t("projects")}</h2>
        </div>
        <p>{t("projectDescription")}</p>
        <ButtonArrow to="/projects" buttonText={t("discover")} />
      </article>
    </section>
  );
};

export default ProjectSection;
