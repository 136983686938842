import React from "react";
import bubblesImage from "../../assets/images/bubbles.png";
import ButtonArrow from "../Button/ButtonArrow";
import "./BubbleCard.css";

const BubbleCard = (props) => {
  let { title, description } = props;
  return (
    <section className="container-fluid d-flex justify-content-center mb-5 flex-column flex-lg-row">
      <div>
        <img
          className="bubbles-image"
          src={bubblesImage}
          alt="bubbles and forms"
        />
      </div>
      <article className="article-card-bubble ml-lg-n7">
        <div style={{ marginBottom: 50 }}>
          <h2 className="card-panel-title">{title}</h2>
        </div>
        <p>{description}</p>
      </article>
    </section>
  );
};

export default BubbleCard;
