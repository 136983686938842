import './subtitle.css';

function SubTitle(props) {
  const subtitleStyle = {
    textTransform: props.uppercase ? 'uppercase' : 'none',
    color: props.color,
    fontSize: props.fontSize,
  };

  return <h3 style={subtitleStyle}>{props.subtitle}</h3>;
}

export default SubTitle;
