import React from "react";
import "./ColumnFooter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function ColumnFooter({ title, text, apiUrl, linktext, data }) {
  return (
    <div className="column-footer">
      <h6>{title}</h6>
      <p>{text}</p>
      <ul className="nav d-flex flex-column">
        {data.map((item, index) => (
          <li className="nav-item d-flex align-items-center m-0" key={index}>
            <FontAwesomeIcon
              icon={faAngleRight}
              size="2xs"
              style={{ color: "#00dcff" }}
            />
            <a
              className="nav-link text-white"
              href={item.apiUrl}
              style={{ padding: "2px", paddingLeft: "5px" }}
            >
              {item.linktext}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ColumnFooter;
