import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroImage from "../../assets/images/HeroDD.png";
import BubbleCard from "../../components/BubbleCard/BubbleCard";
import "./ContactInformation.css";

const ContactInformation = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <HeroSection
        backgroundImage={HeroImage}
        subtitle="Contact"
        showButton={false}
      />
      <div className="contact-container">
        <h2>{t("contactTitle")}</h2>
        <div className="contact-section">
          <h3>{t("universityTitle")}</h3>
          <p>
            {t("universityAddress")}
            <br />
            {t("universityPostalCode")}
            <br />
            {t("universityEmail")}
          </p>
        </div>
        <div className="contact-section">
          <h3>{t("supportCenterTitle")}</h3>
          <p>
            {t("supportCenterAddress")}
            <br />
            {t("supportCenterPostalCode")}
            <br />
            {t("supportCenterEmail")}
            <br />
            {t("supportCenterPhone")}
          </p>
        </div>
        <div className="contact-section">
          <h3>{t("technologyTransferCenterTitle")}</h3>
          <p>
            {t("technologyTransferCenterAddress")}
            <br />
            {t("technologyTransferCenterPostalCode")}
            <br />
            {t("technologyTransferCenterEmail")}
            <br />
            {t("technologyTransferCenterPhone")}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ContactInformation;
